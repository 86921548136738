module.exports = {
  palette: {
    main: "#2F4F94",
    secondary: "#BD1B19",
    light: "#ECF0F9",
    dark: "#070C17",
  },
  typography: {
    primary: "forte",
    secondary: "Manrope",
  },
}
