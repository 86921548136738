exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-cambridge-js": () => import("./../../../src/templates/cambridge.js" /* webpackChunkName: "component---src-templates-cambridge-js" */),
  "component---src-templates-conocenos-js": () => import("./../../../src/templates/conocenos.js" /* webpackChunkName: "component---src-templates-conocenos-js" */),
  "component---src-templates-contactenos-js": () => import("./../../../src/templates/contactenos.js" /* webpackChunkName: "component---src-templates-contactenos-js" */),
  "component---src-templates-cursos-js": () => import("./../../../src/templates/cursos.js" /* webpackChunkName: "component---src-templates-cursos-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-informacion-js": () => import("./../../../src/templates/informacion.js" /* webpackChunkName: "component---src-templates-informacion-js" */),
  "component---src-templates-online-js": () => import("./../../../src/templates/online.js" /* webpackChunkName: "component---src-templates-online-js" */),
  "component---src-templates-oxford-test-of-english-js": () => import("./../../../src/templates/oxford-test-of-english.js" /* webpackChunkName: "component---src-templates-oxford-test-of-english-js" */)
}

